<template>
    <div id="home-page">
        <div class="hero-area">
            <!-- <img class="shape parallax5" src="../assets/images/home/h2-shape.png" alt=""> -->
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="content">
                            <div class="content">
                                <h5 class="subtitle">
                                    Get Emmersed in Metaverse with VR
                                </h5>
                                <!-- <h1 class="title">
                                    <img src="../assets/images/text.png" alt="">
                                </h1>
                                <div class="links">
                                    <a href="#" class="mybtn1">Play Now</a>
                                    <a href="https://www.youtube.com/watch?v=jssO8-5qmag" class="video_btn play-video mfp-iframe">
                                        <i class="fas fa-play"></i>
                                    </a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Hero Area End -->

        <!-- Counter Area Start -->
        <section class="counter-section">
            <div class="pillar-container">
                <div class="pillar-wrap">
                    <img  src="../assets/images/pillars/pillar1.png" alt="">
                    <img  src="../assets/images/pillars/pillar2.png" alt="">	
                    <img  src="../assets/images/pillars/pillar3.png" alt="">
                    <img  src="../assets/images/pillars/pillar4.png" alt="">	
                    <img  src="../assets/images/pillars/pillar5.png" alt="">
                    <img  src="../assets/images/pillars/pillar6.png" alt="">				
                </div>
            </div>
        </section>
        <!-- Counter Area End -->

        <section class="video-sec">
            <div class="video-desc">
                <h2>METADEIOS Virtual Real Estate Developer</h2>
                <span>
                    Build with blockchain system and DeFi Development to raise funds to build endless<br/> possibilities to Own & Explore, to Buy & Sell and to Earn & Harvest. In this virtual world<br/> people can live as an alternative life to socialise, shop and play.
                </span>
            </div>
            <div class="video">
                <video controls="hidden" poster="../assets/images/home/video_preview.png" src="../assets/video/Metadeios_Teaser.mp4" controlsList="nodownload"></video>
                <div class="video-shadow"></div>
            </div> 
        </section>

        <section class="land-sec">
            <div class="land-desc">
                <h2>Own Your Land</h2>
                <span>Mint a land in METADEIOS and open up the door to<br/> passive income with your daily MDT earnings!</span>
                <router-link class="nav-link" to="/map">Own Land</router-link>  

                <!-- <button>Own Land</button> -->
            </div>
        </section>

        <section class="img-sec">
            <div class="img-wrap">
                <div class="img-sec-web">
                    <img class="img" src="../assets/images/home-img/img1.png" alt="">
                    <img class="img" src="../assets/images/home-img/img2.png" alt="">
                    <img class="img" src="../assets/images/home-img/img3.png" alt="">
                </div>
                <div class="img-sec-mobi">
                    <img class="img2" src="../assets/images/home-img/img1.png" alt="">
                    <img class="img2" src="../assets/images/home-img/img2.png" alt="">
                </div>
                <div class="img-sec-mobi">
                    <img class="img2" src="../assets/images/home-img/img3.png" alt="">
                    <img class="img2" src="../assets/images/home-img/img9.png" alt="">
                </div>
                <div>
                    <img class="img2" src="../assets/images/home-img/img4.png" alt="">
                    <img class="img2" src="../assets/images/home-img/img5.png" alt="">
                </div>
                <div>
                    <img class="img2" src="../assets/images/home-img/img6.png" alt="">
                    <img class="img2" src="../assets/images/home-img/img7.png" alt="">
                </div>
                <div class="img-sec-mobi">
                    <img class="img2" src="../assets/images/home-img/img8.png" alt="">
                    <img class="img2" src="../assets/images/home-img/img10.png" alt="">
                </div>
                <div class="img-sec-web">
                    <img class="img" src="../assets/images/home-img/img8.png" alt="">
                    <img class="img" src="../assets/images/home-img/img9.png" alt="">
                    <img class="img" src="../assets/images/home-img/img10.png" alt="">
                </div>			
                
            </div>
        </section>

        <section class="token-sec">
            <div class="token-wrap">
                <h3>METADEIOS Token (MD)</h3>
                <span>
                    is a BEP-20 utility token built on the<br/>
                    Binance Smart Chain network, enabling<br/>
                    METADEIOS investors to trade and earn<br/>
                    from the ownership of NFT lands,<br/>
                    properties, and avatars.
                </span>
                <router-link class="nav-link" to="/map">Own Land</router-link>  

                <!-- <button>Own Land</button> -->
            </div>
        </section>

        <!-- How play area start -->
        <section class="how-to-play">
            <div class="partner-bg">
                <div class="community-wrap">
                    <div class="community-left">
                        <img src="../assets/images/home/community.png" alt="">
                    </div>
                    <div class="community-right">
                        <h2>Metadeios<br/> Community</h2>
                        <span>Find answer, support, and inspiration from other METADEIOS users.</span>
                        <button>Join Us</button>
                    </div>
                </div>
                <div class="partners-wrap">
                    <div>
                        <h3>Our Trusted Partners</h3>
                    </div>
                    <div class="partners-logo">
                        <div class="partners-col">
                            <img src="../assets/images/partners/tr.png" alt="">
                            <img src="../assets/images/partners/cg.png" alt="">
                            <img src="../assets/images/partners/ps.png" alt="">
                        </div>
                        <div class="partners-col">
                            <img src="../assets/images/partners/af.png" alt="">
                            <img src="../assets/images/partners/cmc.png" alt="">
                            <img src="../assets/images/partners/tw.png" alt="">
                        </div>		
                        <div class="partners-col">
                            <img src="../assets/images/partners/bsc.png" alt="">
                            <img src="../assets/images/partners/hb.png" alt="">
                            <img src="../assets/images/partners/meta.png" alt="">
                        </div>
                        <div class="partners-col">
                            <img src="../assets/images/partners/bs.png" alt="">
                            <img class="mdex" src="../assets/images/partners/mdex.png" alt="">
                            <img src="../assets/images/partners/it.png" alt="">
                        </div>			
                    </div>
                </div>
            </div>
            
            <div class="subscribe-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="subscribe-box">
                                <img class="left" src="../assets/images/vr.png" alt="">
                                <img class="right" src="../assets/images/game controler_.png" alt="">
                                <div class="row justify-content-center">
                                    <div class="col-lg-12">
                                        <div class="heading-area">
                                                <h5 class="sub-title">
                                                    Subscribe to Metadeios
                                                </h5>
                                                <h4 class="title">
                                                    To Get Exclusive Benefits
                                                </h4>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-6 col-12">
                                        
                                        <form action="#" class="form-area">
                                                <input type="text" placeholder="Your Email Address">
                                                <button class="mybtn1" type="submit">Subscribe
                                                </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- How play area  end -->
        
        <!-- Footer Area Start -->
        <footer class="footer" id="footer">
        
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="flogo">
                            <a href="#"><img src="../assets/images/logo/Logo-Ver.png" style="max-width:150px;" alt=""></a>
                        </div>
                        <div class="social-links">
                            <ul>
                                <li>
                                    <a href="https://twitter.com/metadeios" target="_blank">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://discord.com/invite/gU35KRrR" target="_blank">
                                        <i class="fab fa-discord"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="footer-menu">
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="copy-bg">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                                <p>Copyright © 2022.All Rights Reserved By Metadeios</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer> 
        <!-- Footer Area End -->

        <!-- Back to Top Start -->
        <div class="bottomtotop">
            <i class="fas fa-chevron-right"></i>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

.video-sec{
  background: radial-gradient(circle, rgba(113,37,154,1) 0%, rgba(25,24,79,1) 100%);
  padding: 5% 0;
}

.video-desc{
  box-shadow: inset 0px 0px 30px #00a2ff;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap; */
  text-align: center;
  padding: 40px;

}

.video-sec .video-desc h2{
  font-size: 45px;
  text-shadow: 0 0 4px #ffffff;
}

.video-desc span{
  color: white;
  line-height: 1.8;
}

.video-sec .video{
  text-align: center;
  border-radius: 20px;
  width: 80%;
  overflow: hidden;
  position: relative;
  /* box-shadow: inset 0px 0px 30px #00a2ff; */
  margin: 0 auto;
  margin-top: 30px;
}

.video-sec .video-shadow{
  box-shadow: inset 0px 0px 30px #00a2ff;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.video-sec video{
  border-radius: 20px;

  height: auto;
  width: 100%;
  /* box-shadow: inset 0px 0px 30px #00a2ff; */

}

.land-sec {
  background: url(../assets/images/home/land.jpg);
  /* background-size: 100% auto; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 10%;
}

.land-desc{
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
}

.land-sec .land-desc button,
.land-sec .land-desc a{
  border-radius: 30px;
  box-shadow: inset 0 0 20px #571480;
  padding: 8px 40px;
  background: transparent;
  border: 0px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-top: 35px;
}

.land-sec .land-desc h2{
  text-shadow: 0 0 10px #001F3B;
  font-size: 2rem;
}

.land-sec .land-desc span{
  text-shadow: 0 0 10px #001F3B;  
  color: white;
  line-height: 1.9;
}

.img-sec{
  background: linear-gradient(315deg, rgba(87,20,128,1) 0%, rgba(8,8,25,1) 100%);
  padding: 2% 0;
  text-align: center;
  padding: 3% 7%;
}

.img-sec .img-wrap .img{
  width: 30%;
  margin:5px;
}

.img-sec .img-wrap .img2{
  width: 45%;
  margin:5px;
}

.token-sec{
  text-shadow: 0 0 10px #001F3B;  
  color: white;
  line-height: 1.9;

  background: url(../assets/images/home/token.png);
  background-size: cover;
  background-position: center 70%;
  background-repeat: no-repeat;

  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 10%;
  
}

.token-wrap{
  display: flex;
  flex-wrap: wrap;
  max-width: 357px;
}

.token-wrap h3{
  font-weight: bold;
}

.token-sec button,
.token-sec a{
  border-radius: 30px;
  box-shadow: inset 0 0 15px #0086FF;
  padding: 8px 50px;
  background: transparent;
  border: 0px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-top: 35px;
}

.partner-bg{
  background: linear-gradient(195deg, rgba(87,20,128,1) 0%, rgba(8,8,25,1) 80%, rgba(32,8,68,1) 100%);
  padding-bottom: 8%;
  padding-top: 3%;
}

.community-wrap {
  display: flex;
  padding: 5% 10%;
  gap: 30px;
}

.community-wrap .community-left{
  width: 50%;
  padding-top: 0;
  padding-right: 10%;
  text-align: right;
}

.community-right{
  color: white;
  text-align: left;
  /* max-width: 640px; */
  max-width: 580px;
  padding-top: 5%;
/* 
  display: flex;
  flex-wrap: wrap; */
}

.community-left img{
  width: 80%;
  max-width: 400px;
}

.community-right h2{
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 25px;
  font-size: 2rem;
}

.community-right span{
  font-size: 1rem;
}

.community-right button{
  border-radius: 30px;
  box-shadow: inset 0 0 10px #FFB100;
  padding: 10px 30px;
  background: transparent;
  border: 0px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-top: 25px;
  max-height: 41px;
}

.partners-wrap h3{
  color: #FFDD64;  
  font-weight: bold;
  text-align: center; 
}

.partners-col{
  width: 25%;
  text-align: center;
}

.partners-col img{
  max-width: 337px;
  margin: 15px;
  width: 75%;
}

.partners-logo{
  display: flex;
  padding: 3% 10%;
}

@media screen and (max-width: 1024px){
    
    .hero-area .content .content .subtitle{
        font-size: 34px;
    }
    .counter-section .pillar-wrap{
        flex-wrap: wrap;
    }
    .pillar-wrap img{
        width: 80px;
        margin: 1%;
    }
    .video-sec .video-desc h2{
        font-size: 34px;
    }
    .video-sec .video-desc span{
        font-size: 16px;
    }
    .img-sec{
        padding: 5% 7%;
    }
    .img-sec-mobi{
        display: block
    }
    .img-sec-web{
        display: none
    }
    .token-sec span{
        font-size: 16px;
    }
    .community-wrap {
        padding-top: 8%;
        flex-wrap: wrap;
    }
    .community-wrap .community-left{
        width: 100%;
        padding-right: 0;
        text-align: center;
    }
    .community-wrap .community-left img{
        max-width: 250px;
    }
    .community-wrap .community-right{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .community-wrap .community-right h2{
        font-size: 45px;
    }
    .community-wrap .community-right span{
        font-size: 16px;
    }

    .partners-logo{
        display: flex;
        flex-wrap: wrap;
    }

    .partners-col{
        width: 50%;
    }
    .partners-col .mdex {
        margin: 13px 0;
    }

    .partners-wrap{
        padding-top: 5%;
    }

    #home-page .footer{
        padding-top: 220px;
    }
}

@media screen and (min-width: 1025px){
    .img-sec-mobi{
        display: none
    }
    .img-sec-web{
        display: block
    }

    .partners-col .mdex {
        margin: 10px 0;
    }

    #home-page .footer{
        padding-top: 12%;
    }
    
}

</style>