import Vue from "vue";
import VueRouter from  "vue-router";
import Home from '../views/home.vue';
import MetaMap from '../views/map.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: "/home",
        name: "Home",
        component: Home,
    },
    {
        path: "/map",
        name: "Map",
        component: MetaMap,
    },
    { path: "*", redirect: "/home" },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });
  
  export default router;