import axios from "axios";

let metadeiosApi = "https://api.metadeios.com/api"

export async function axiosPost(url , param){
    return await axios.post('https://api.metadeios.com/api/'+url, {
            trxHash: param,
        },
        { headers: {'Authorization' : `Bearer ${sessionStorage.bt}`} }
    ).catch((error) => {
        console.log(error);        
    }).then((response)=>{
        return response;
    }) 
}

export async function mapDataApi () {
    return await axios.get(            
        metadeiosApi+'/map/all/1'
    ).catch((error) => {
        console.log(error);
    }).then((response)=>{
        console.log(response);
        return response;
    });
}

export async function buyLandApi (id,row,msg,sign,name) {
    console.log(id.toString(),row.toString(),msg,sign,name)
    return await axios.post(
        metadeiosApi+'/map/editName',{
            token_id: id.toString(),
            no_row: row.toString(),
            message: msg,
            signature: sign,
            name: name,     
        },
    ).catch((error) => {
        console.log(error);
    }).then((response)=>{
        // console.log(response);
        return response;
    });
}