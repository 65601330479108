<template>
  <div class="map-page">
    <!-- <h1>{{ msg }}</h1> -->
    <div style="display: none;">
      <img id="map" :src="require('../assets/images/map/map-crop/area'+areaSelected+'.jpg')" alt="map">
    </div>
    <!-- <div>{{zoomValue}}</div> -->
    <svg @keydown.space="drag" @wheel="zoom" version="1.1" id="mapSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" :viewBox="mapViewX +' '+ mapViewY +' '+ mapViewW +' '+ mapViewH" style="enable-background:new 0 0 814 458;" xml:space="preserve">
      <image x="0" y="0" width="1920px" href="../assets/images/map/metadeios_map.jpg" />
      <path class="st0" value="" d="M93.14,208.15l14.8,6.11L147.2,210s38.64-10.79,44.33-14.91,22-33.73,23.93-40l36.48-19.81,57.87-32.36,83-8.44,42-23.73,20.07,1.61S461,59.18,472.2,59.7c0,0,3-8.42,9.13-7.37s16.33,3.16,16.15,11.94c0,0,19-4.39,24.58,10.36l60-2.11s22.12,8.43,38.45,9.3,25.8,15.1,25.8,15.1l28.09,37-2.1,14L672,164.86l-14.22,14.22s1.22,23.7,7.19,38.1l-5.44,13.34-4.39,32.3s-7.37,22.12-12.11,27.39a132.58,132.58,0,0,0-4.57,43.71s-25.28,43.36-31.24,60.39l-49.69,17.91H476.33l-18,19.89-8.45,3-6.58,5.58,1.72,13.17-6.73,9.58L409.42,479h-8.16l-3.72,5.3s-31.33,6-45.36,3.86c0,0-23.61-11.59-34.48,1.86,0,0-46.93-1-82.42-10.45s-40.64-17.88-77.41-18.31L145.56,444s-19,4.58-22.18-7.58S127,422.09,127,422.09l-3.72-3.43-11.59,3.43s-10.73-6.72-14.74-17.31L76.59,388.46S47.4,375.3,45,372.87v-8L25.22,342.53l9.16-17.74s9.16-40,11.3-53.64,4.15-24.63,1.86-39.94Z" />      
      <path class="st0"  d="M916.51,142.89s-110.14,5.09-129,15.26l-16.12,10.67a13.09,13.09,0,0,0-5.63,8.43c-.89,4.59-1.57,10.94.33,14.87a5.93,5.93,0,0,1-.42,5.87c-2,3-4.35,7.61-3.45,11.38,1.49,6.27,10,8.35,10,8.35l1.05,18.5,11.93,40.13,17.75,10.74L908.51,266.2S941.7,257,956.78,243a15.52,15.52,0,0,1,7.17-3.86l34.65-7.77a19,19,0,0,0,7.41-3.43,27.7,27.7,0,0,0,9.87-14.45,9.35,9.35,0,0,0,.33-2.53c0-4.09.17-17.19,1.79-26,1.94-10.59,19.24-83.54,19.24-83.54s-13-15.36-21.93-15.66c0,0-45.64,16-52.95,24.17S916.51,142.89,916.51,142.89Z" />
      <path class="st0"  d="M940.54,286.94c-13-.21-61.17,2.39-86.65,49.89l-20.31,27.08-7,9.7-13.94,18a11.59,11.59,0,0,1-4.84,3.56,7.8,7.8,0,0,0-4.52,5.26,11.55,11.55,0,0,1-2.47,4.4c-4.69,5.08-16.46,17.88-21.81,24.4-6.77,8.23-43.92,34-56,41.36-4.83,2.93-7.35,11.94-8.63,21.71a109.86,109.86,0,0,0,9.39,60.34,157.55,157.55,0,0,0,26,39.83c23,25.24,55,102.35,65.72,129.16,1.82,4.57,3.45,9.21,4.95,13.89,3.74,11.72,16.58,42.48,50.3,52.16,41.95,12,80.47,28.54,195.32-26.14,0,0,31.64-15.13,15.14-43.33s-27.17-67.06-13.07-95.94,87-60.18,87-60.18,12.33-8.48,15.9-22.11a118.44,118.44,0,0,1,9.68-24.82c5.29-10.11,11-25.85,12.25-62.08,0,0,6.19-61.56-24.07-84.6a493.54,493.54,0,0,0-47.34-32,11.3,11.3,0,0,1-5.57-10.64c.69-8.47-.31-22.88-12.78-32.35-18.57-14.1-32,9.29-32,9.29l-1.5,1.13a15.86,15.86,0,0,1-16.43,1.58c-15.43-7.51-51.87-21.48-105.21-18.7C945.56,287,943.05,287,940.54,286.94Z" />
      <path class="st0"  d="M1185.8,101.75,1180,88.44,1165.31,77.8l-36.45-1.6-11.18,4.53-26.07,19.42-4.53,4-3.72,7.19,3.72,9.84.8,9.58,1.33,5.86,8.52,6.91h6.38l-9.31,20,13.84,33.26s-15.44,3.73-15.7,17.57,21.55,33.26,21.55,33.26,17.83,15.16,24.48-3.46l17.3,17.83,48.16,36.45,4,10.91,16.77,16.76,7.45,27.15,14.1,25.27-2.4,10.91,7.72,5.59,16.76,2.13,49.76,5.06,7.72-13s35.92-22.09,52.42-49.5l20.49-19.95L1482,282.42s1.34-12.24,18.1-17,22.35.53,22.35.53,30.6-13,31.67-16.23,23.41-10.52,23.41-10.52l11.71-5.72,4.52-23.94,1.87-4,29.8,15.7s10.11,2.66,26.61-.26c0,0,13.57,9.58,29.53,3.19s32.73-12.51,49.23-41.78,18.63-41.51,12-46.3-17.57-17-28.74-11.7-48.17,12.77-48.17,12.77l-11.44,4.52L1688.72,123l17-17,4.52-24.22.53-5.59-12.24-26.07-43.1-5.59L1605.7,55.71,1575.9,57l-17.3-4.52L1541,54.12l-13.57-9.58-14.11,6.65L1387.5,47.73l-52.68-1.6-20.49,11.45L1271,75.4l-6.38,10.31-13.84,8.59-20.22,8.25L1202.83,106Z" />
      <path class="st0"  d="M75.9,774.6s4.24-8.68,12.71-12.28S117,749.19,117,749.19l16.09-1.27s24.34,7,26,18.63,11.43,17.15,21.17,14.82c9.42-2.25,10,16.28-3.63,16.9a22.94,22.94,0,0,0-4.51.59c-16,4.06-23.38,13.22-15.57,24,0,0,27.85,18.93,27.25,45.34.6,28.11,36.47,29.32,36.47,29.32s59.85,14.3,59.37,31.62c-.45,15.88-19.93,20.16-50.42,29.25a26.19,26.19,0,0,1-17.1-.74l-18-7.09a1.29,1.29,0,0,0-1.68,1.65c2.28,6.23,3.58,20.28-33.66,16.27,0,0-32.75-5-27.19-18.93,0,0,.95-2.3,4.89-4.08a1.19,1.19,0,0,0-.33-2.27c-10.28-1.3-31-4.88-41.81-13.47l-8.05-5.37a18.53,18.53,0,0,0-3.65-1.88c-2.27-.89-6.14-2.63-8.16-4.89a2.38,2.38,0,0,0-3.53-.08c-1.68,1.79-4.15,3.48-6.8,2.15-4.81-2.4-4.36-13.22-4.36-13.22l-3-4.65s-19.53-5.71-14.57-17.88,9.91-8.26,9.91-8.26,2.25-11.86,3-12.76-4.5-6-5.1-6-17.88-6-12.62-17.88c0,0,.45-4.65,4.06-5.7,0,0-3.61-7.06,2-8.12,2.47-.46,4,.11,5,.89a2.83,2.83,0,0,0,3.85-.38l1.2-1.35a5.44,5.44,0,0,0,1.41-3.8c-.09-3,.13-8.15,2.68-8.57s1.32-5.58.4-8.51a6.62,6.62,0,0,1,0-4.15h0a3,3,0,0,0-.22-2.47c-1.65-2.92-5.58-10.43-2.93-11.76,3.31-1.65,4.81-2.85,5.11-.75S66.15,775.08,75.9,774.6Z" />
      <path class="st0"  d="M228.41,678.12a8.28,8.28,0,0,1,1.25,7.59c-.83,2.55-1.49,5.42-.75,6.62,1.47,2.38-9.15,5.13-6.77,9.88s-.18,14.28,6,13.18-1.1,9,1.65,12.26-11.17,6-12.45,22c-1.2,15,.82,21.68,38.51,41.69a50.11,50.11,0,0,0,17.53,5.5c12.94,1.54,35.68,2.93,48.64-4,0,0,7.73-4.09,14.32-5.47a14.81,14.81,0,0,1,6.35.24c1.69.39,4.67,0,7.88-5.21,3.09-5,11.47-10.42,17.32-13.75a35.5,35.5,0,0,0,12.95-12.49c1.74-2.91,3.07-5.94,2.86-8.21-.43-4.69-4.45-44.56-6.18-61.62a62.86,62.86,0,0,1,0-11.64c.61-7.1-2.3-21.31-35.38-21.31-44.11,0-26.17,13.18-26.17,13.18s7.67,3.68,20.63.42a28,28,0,0,1,9.34-.74c12.26,1.14,4.09,8.78-8.56,11.66,0,0-5.49,3.12-24.34-4.57-12.85-5.24-26.47-3.34-33.83-1.59a2.76,2.76,0,0,1-3.25-3.52c1-3.27,1-7.14-3.37-8.43-8-2.38-22.33-6.41-24.7-4.76s-6.78,11.89-7.87,12.26-7.78-.38-8.84,2.42a12.08,12.08,0,0,1-1.3,2.59c-1.17,1.7-2.65,4.36-1.58,5.79Z" />
      <path class="st0" @click="showGrid($event, 0)" d="M482.22,663.8s-21.3-11.47-27.19,2-8.85,14.42-13.43,19-21,16.06-15.07,27.85S416,738.49,416,738.49s-12.77-9.83-16.38-6.55,4.26,11.46,6.88,12.12-14.74,2-14.08,23.26,14.74,24.24,17,22.6-7.53,10.81-2,13.43,10.48,13.76,16.05,14.09c3.84.23-11.64,29.89-21.62,48.29a78,78,0,0,1-16.52,21c-4.49,4-9.37,9.31-11,14.53-3.28,10.48-21.62,28.17-21.62,28.17l-3.91,3.8a65.88,65.88,0,0,1-14.71,10.88c-8,4.27-21,13.81-25.61,32.17-6.88,27.19,35.71,37,35.71,37L448,1035.69a109.11,109.11,0,0,0,56.27-2.65c22.71-7.27,55.59-21.53,94.63-49.42a29.9,29.9,0,0,1,17.22-5.75c5.79,0,14-2,21.42-10.72a10.24,10.24,0,0,0,4.42-5.19,27.05,27.05,0,0,1,12.29-13.68l45.21-23.39s6.55-10.81,4.25-19-4.58-29.48-3.6-37.67,31.45-73.38,31.45-73.38,8.84-31.45-19.66-41c0,0,5.57-15.4-6.55-16.38S685,726.35,684,725.7s-8.52-5.58-18.68,0c0,0-22.27-3.92-45.2-30.45,0,0-12.08-9.31-38.22-14a14.63,14.63,0,0,1-11.86-12.4c-.94-6.51-4-14.18-12.82-17.46-16.71-6.23-18-13.43-26.21,3.6,0,0-4.37,9.17-10.7,8.52-3.43-.36-6.83-.33-9,.61a14.25,14.25,0,0,1-12.88-.51l-.16-.1C495,661.5,489.42,652.66,482.22,663.8Z" />
      <path class="st0"  d="M896.59,866.41s38-47.86,82.52-7.85c0,0,17.69,28.21,0,47,0,0-41.26,36.44-65.91,39.65,0,0-79.85-3.75-84.14-30S896.59,866.41,896.59,866.41Z" />
      <path class="st0"  d="M1043.81,923.44a68.06,68.06,0,0,0,17.17-6c5.5-2.82,13.3-5.36,21.56-3.37,9.48,2.29,24.14-.61,34.14-3.22a22.19,22.19,0,0,1,20,4.6l26.11,22.29a28.56,28.56,0,0,1,9.92,19.23c1.18,13.28,2.29,33.81-1.23,40.16-4,7.21-45.11,33.86-65.64,46.88a65.77,65.77,0,0,1-26.49,9.67,459.92,459.92,0,0,1-99.72,1.86S927.84,1062,907.93,1070c-11,4.42-22.86.9-31.35-3.16a72.08,72.08,0,0,0-25.29-6.86c-13.12-1.06-31.85-6.17-38.07-25.87-8.44-26.74-14.25-54,28.15-66.79A89.92,89.92,0,0,1,883,965.1c18.89,3.32,52.84,6.78,81.14-3.77,41.71-15.56,61.9-24.83,61.9-24.83S1029.82,926.36,1043.81,923.44Z" />
      <path class="st0"  d="M1230.68,554.92l22.44-62.33s0-9.51,5.48-15.44a14.87,14.87,0,0,0,3.85-9.53c.33-8.81,3.21-23.43,18.74-23.08h4.54a14.25,14.25,0,0,0,10.29-4.4c5-5.25,15-13.35,28.32-13.35,0,0,30.3-3.14,53.85-34.27a16.18,16.18,0,0,0,3.25-8.11c.28-2.68,1.5-6.16,5.78-7.55a16,16,0,0,0,7.18-4.88c9-10.56,38.84-38.7,115.35-61.07,96.16-28.11,161.74-13.56,176-5.18s60.9,30.33,35.51,107.5c-21.13,58.88,1.1,46.13,1.89,106a8,8,0,0,0,5.23,7.54c3.19,1.1,6.76,4.34,7.37,13.2,1.13,16.54-1.7,27.59-6.23,30.7s9.06,19.55-6,32c0,0-9.63,6.51-4.82,10.48,0,0,8.78,19.26-7.93,27.47,0,0-14.16,13-29.17,9.06s-29.17-8.21-35.68-6.8-29.46,10.48-41.64,12.18c-10,1.41-86.49-2.21-112.81-3.52a26.36,26.36,0,0,1-14.46-5.15c-5.4-4-13.76-8.78-20.57-6.34a81.15,81.15,0,0,1-10.39,3,11.75,11.75,0,0,1-9.26-1.76,10.65,10.65,0,0,0-11.5-1c-8.22,4.24-10.2,9.34-20.11,9.91-4.55.26-8.14.28-10.7.23a9.9,9.9,0,0,1-7.23-3.42c-3.33-3.82-9-9.58-12.09-8.42-4.53,1.7-9.35,8.49-14.45,9.91s-13.31.85-14.44,0-6.52-.57-11-6.52-1.13-8.21-9.34-5.09-6.8-15.58-12.18-16.43-13.6,2.55-20.39,3.68-12.75.29-13.6-3.11.28-10.77-6.8-12.75-10.19-13.88-15-15-9.06,4-13.87-.85-2.84-12.46-6.24-15.29c-2.9-2.42-1.33-14.39-5.74-17.71A4,4,0,0,1,1230.68,554.92Z" />
      <path class="st0"  d="M1709.31,643.77a.77.77,0,0,0-1.16-.94c-4.83,3.53-15.41,9.81-26.46,6.89-15-4-29.17-8.21-35.68-6.8s-29.46,10.48-41.64,12.18c-10.06,1.41-86.5-2.21-112.82-3.52a26.36,26.36,0,0,1-14.44-5.14c-5.41-4-13.77-8.79-20.58-6.35a81.15,81.15,0,0,1-10.39,3,11.75,11.75,0,0,1-9.26-1.76,10.65,10.65,0,0,0-11.5-1c-8.22,4.24-10.2,9.34-20.11,9.91-4.55.26-8.14.28-10.7.23a9.9,9.9,0,0,1-7.23-3.42c-3.33-3.82-9-9.58-12.09-8.42-4.53,1.7-9.35,8.49-14.45,9.91s-13.31.85-14.44,0-6.52-.57-11-6.52-1.13-8.21-9.34-5.09-6.8-15.58-12.18-16.43-13.6,2.55-20.39,3.68-12.75.29-13.6-3.11.28-10.77-6.8-12.75-10.19-13.88-15-15-9.06,4-13.87-.85-2.84-12.46-6.24-15.29c-2.9-2.42-1.33-14.39-5.74-17.71a4.32,4.32,0,0,1-1.24-1.46,2.43,2.43,0,0,0-3.64-.78c-3.65,2.79-8.86,7.73-8.47,12.94.59,7.95-2.19,11.53-2.19,11.53s-5.57,4-6.17,10.54c-.45,4.93-7.39,15.35-10.83,20.24a4.52,4.52,0,0,1-4.46,1.88c-2.15-.39-5.13-.29-6.78,2.55a5.92,5.92,0,0,0-.35,5.86,7,7,0,0,1-.13,6.11l-15.83,32.38S1158.52,672,1157.73,686s3.06,43.66-11.26,55.4-17.86,70.93,18.55,75.4,63,41.1,66.71,55.66,32.18-8.69,55.19-1.88,55.2,23,61.31,49.56,215.82,67.19,267.19-19.93,76.6-59.78,82.31-135.79C1703.13,692.53,1701,662.37,1709.31,643.77Z" />
      <path class="st0"  d="M1787.78,562.47a20.19,20.19,0,0,1,6-8.44c7.14-5.92,23.67-16,48.51-9.63l4.14.92a7.78,7.78,0,0,0,5.25-.72c3.62-1.88,10.92-4.29,16.78,2.57,4.4,5.15,4,8.68,2.77,10.83a5.77,5.77,0,0,0,.31,6.54h0a2,2,0,0,0,1.25.75c1.28.21,3.85.93,4.58,3.28,1,3.18,4.17,11.3,3.77,14.28s10.9,21.4-13.08,27.35-41.62-3.18-46.58-14.47c0,0-1.58-10.11-8.32-9.91C1806.77,586,1778.16,588.33,1787.78,562.47Z" />
      <path class="st0"  d="M1774.1,386.61s24.71-23.61,34.64-18.31,11.48,15.66,11.92,23.17c.39,6.62,2.33,27.86,11.58,38.37a8.2,8.2,0,0,0,7.75,2.57c4.2-.83,10.85-1.19,13.33,4.07a36.61,36.61,0,0,1,2.56,7,9.09,9.09,0,0,0,5.74,6.39c2.17.79,4.34,1.87,4.94,3.18,1.33,2.86,5.3,9.48,9.27,8.82s7.94-4.85,9.93,1.11c1.56,4.68,4.48,9.9,3.63,12.57a8.08,8.08,0,0,0-.22,4.86c1.2,3.87,1.46,9.93-7.82,11.25-13.91,2-48.55,22.73-57.82,31.78-7.77,7.59-31.84,23.49-50.34,22a13.89,13.89,0,0,1-12.65-14,49.61,49.61,0,0,1,2.75-14.79c6.17-19-13-49.43-13-49.43s-4.87-9-3.81-26.67a15.58,15.58,0,0,0-4.14-11.44c-2.51-2.73-4.88-6.37-4-9.78,1.76-6.62,1.76-13.46,10.81-14.12a49.6,49.6,0,0,1,8.11-.17,2.93,2.93,0,0,0,2.29-.77l.41-.38a4.69,4.69,0,0,0,1.46-3.71c-.18-2.66.34-7,5.61-7.15C1767,393,1770.45,387.07,1774.1,386.61Z" />
      <path class="st0" @click="showGrid($event, 13)" d="M1750.73,764s62.43-42.3,109,49.72a20.06,20.06,0,0,1,1.7,13.61,28.86,28.86,0,0,1-4.56,10.57c-5.55,7.56.23,28.67,6.67,45.78s-8.44,22.88-11.78,25.77-11.11,15.56-7.11,26.89-70.66,3.33-70.66,3.33-16.89,2-21.78,6-29.11,6.22-29.11,6.22-43.33,1.78-52-16.44,8.66-43.78,21.77-53.55,64.67-43.33,66.89-61.11.22-27.55-1.78-32.22S1745.47,768.36,1750.73,764Z" />
    </svg>
    <div class="testing">
      <button class="testingBtn" @click="clickZoom('+')">+</button>
      <button class="testingBtn" @click="clickZoom('-')">-</button>
    </div>    
    <section @click="close" id="modal-1" class="map-modal-container">  
      <div class="map-modal-dialog">    
        <svg class="map-modal-svg" :width="mapW" :height="mapH" viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon class="map-modal-polygon" />
          <!-- <image class="modal-map" x="-5" y="-10" width="100" href="../assets/images/map/metadeios_map.jpg" /> -->
        </svg>
        
        <div class="map-modal-content">
          <!-- <h2>I'm a modal</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis excepturi ut inventore consectetur quos rerum quibusdam accusamus, labore itaque assumenda consequatur cum saepe velit quidem ipsa facilis. Repellendus, reiciendis quam?</p> -->
          <canvas ref="imagecanvas" :width="cw" :height="ch" @click.stop="selectGrid" id="mapCanvas"></canvas>
          <!-- <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="600 30 100 458" style="enable-background:new 0 0 814 458;" xml:space="preserve">
              <image x="-5" y="-10" width="900" href="../assets/images/map/metadeios_map.jpg" />
          </svg> -->
        </div>        
      </div>
    </section>
    
    <section v-if="infoOpen" class="info-slider">
      <div @click="infoOpen = false" class="close-slider">x</div>
      <div class="top-info">
        <div class="info-pic">
          <img src="../assets/logo.png" alt="land">
        </div>
        <p class="title">
          {{ landName }}
        </p>
        <div class="sub-title">
          {{ username }}
        </div>
      </div>
      <div class="bottom-info">
        <div class="info-wrapper">
          <div class="sub-title">
            Location
          </div>
          <div class="value">
            {{ landLotX + ", " + landLotY }}
          </div>
        </div>
        <div class="info-wrapper">
          <div class="sub-title">
            Size
          </div>
          <div class="value">
            {{ landSize+"x"+landSize }}
          </div>
        </div>
        <div class="info-wrapper">
          <div class="sub-title">
            Status
          </div>
          <div class="value">
            {{ landStatus }}
          </div>
        </div>
      </div>
      <div class="info-btn" v-if="this.landStatus == 'Available'">
        <button @click="buyLand">Buy</button>
      </div>
      <div class="info-btn" v-else>
        <button disabled>Buy</button>
      </div>
    </section>
  </div>
</template>

<script>
import {mapDataApi, buyLandApi} from '../axiosApi.js'
import {gsap, Power4, Power3, Power2} from 'gsap'
import {walletType} from '../wallet.js'
import {ethers} from "ethers";


export default {
  name: 'map',
  
  data () {
    return {
      size: 60,
      boxes: 10, //small: 60, medium: 70, large: 80
      // wh: 20,
      map: [],
      cw: 900, // canvas width
      ch: 900, // canvas height
      isDragging: false,
      startX: 0,
      startY: 0,
      offsetX: 0,
      offsetY: 0,
      bb: 0, // bounding borders
      modal: {
        animation: null,
        container: null,
        content: null,
        dialog: null,    
        isOpen: false,
        // open: open,
        // close: close   
      },
      modalAnimationX: 0,
      modalAnimationY: 0,
      point1: null,
      point2: null,
      point3: null,
      point4: null,
      body: null,
      mapViewX: 0,
      mapViewY: 0,
      modalMapViewX: 0,
      modalMapViewY: 0,
      mapViewW: 1920,
      mapViewH: 1080,
      zoomValue: 1,
      zoomMulti: 1,
      scale: 1,
      viewBox: null,
      margin: 50,
      state: [],
      infoOpen: false,
      msg: 'Metadeios',
      landName: "Here u put the land name",
      username: "thisIsUsername",
      landLotX: 0,
      landLotY: 0,
      landSize: 1,
      mapW: 0,
      mapH: 0,
      areaSelected: 0,
      landStatus: 'On sales, Sold, Not for sales',
      crossImg: "",
      redBox: "",
      region1: [],
      zoomType: 0,

    }
  },
  async mounted () {
    console.log('mounteddddd')

    this.getMapData();
    this.crossImg = new Image();
    this.crossImg.src=require("../assets/images/map/map-icon/cross.png");
    this.redBox = new Image();
    this.redBox.src=require("../assets/images/map/map-icon/red_box.png");
    // this.handleResize()
    let map = document.getElementById("mapSvg")
    let canvas = document.getElementById("mapCanvas")
    let ctx = canvas.getContext('2d')
    // this.cw = canvas.width;
    // this.ch = canvas.height;
    this.cw = this.size * this.boxes
    this.ch = this.size * this.boxes

    this.bb = canvas.getBoundingClientRect();
    this.offsetX=this.bb.left;
    this.offsetY=this.bb.top;
    this.body = document.body
    
    this.state = new Array(this.boxes);
    for (var y = 0; y < this.boxes; ++y) {
        this.state[y] = new Array(this.boxes);
    }

    // document.body.appendChild(canvas);
    // Stroke
    // ctx.moveTo(0,0) // starting point
    // ctx.lineTo(50,50) // bottom right x, y
    // ctx.stroke()

    // window.resize = this.handleResize;

    // listen for mouse events
    // canvas.onmousedown = this.handleMouseDown;
    // canvas.onmouseup = this.handleMouseUp;
    // canvas.onmousemove = this.handleMouseMove;
    map.onmousedown = this.handleMouseDown;
    map.onmouseup = this.handleMouseUp;
    map.onmousemove = this.handleMouseMove;

    // set canvas w & h
    // canvas.w = (window.innerWidth > 0) ? window.innerWidth : screen.width
    // canvas.h = (window.innerHeight > 0) ? window.innerHeight : screen.height

    // Rect
    ctx.beginPath();

    // this.bgImg(0, 1)

    // ctx.strokeRect(0, 0, 10, 10); // (top left x, y, bottom right x, y)
    // ctx.fillRect(0, 0, 10, 10);
    

    // this.map.push({x:0, y:0, width:50, height:50})

      ctx.fillStyle = "black"
      ctx.fillRect(0,40,this.size*12,this.size*12)

      // ctx.fillStyle = "#2AC161"
      ctx.strokeRect(300,300,this.size*3,this.size*3)
      ctx.fillRect(300,300,this.size*3,this.size*3)
      // ctx.strokeRect(300,300+this.size*3,this.size*3,this.size*3)
      // ctx.fillRect(300,300+this.size*3,this.size*3,this.size*3)
      // ctx.strokeRect(300,300+this.size*6,this.size*3,this.size*3)
      // ctx.fillRect(300,300+this.size*6,this.size*3,this.size*3)
      ctx.strokeRect(300+this.size*3,300+this.size*3,this.size*6,this.size*6)
      ctx.fillRect(300+this.size*3,300+this.size*3,this.size*6,this.size*6)
    // ctx.stroke();
      this.draw()

  },
  watch: {
    infoOpen () {
      if (!this.infoOpen) {
        this.draw()
      }
    }
  },
  computed: {
    canvas: function () {
      // console.log(this.$refs.imagecanvas)
      return this.$refs.imagecanvas;
    },
    ctx: function () {
      // console.log(this.canvas.getContext('2d'))
      return this.canvas.getContext('2d');
    },
  },
  methods: {
    async buyLand () {
     await this.$swal.fire({
        title: 'Purchase land.',
        text: 'Enter a name for your land.',
        input: 'text',
        // inputLabel: "Your land's name",
        inputValue: this.landName,
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'Your land needs a name!'
          }
        }
      }).then(async (result) => {
        this.landName = result.value
        if(result.isConfirmed){
          console.log(this.landName)
          let providerType = await walletType()
          let provider = new ethers.providers.Web3Provider(providerType)                
          const signer = provider.getSigner()
          let message = 'Purchase a Metadeios land';
          let signature = await signer.signMessage(message);
          console.log(message, signature)
          const buyResponse = await buyLandApi(this.landId,1,message,signature,this.landName);
          console.log((buyResponse.data));
          if(buyResponse.data.status == "success"){
            this.$swal.fire({
                icon: 'success',
                title: 'Your land has been purchased',                                       
                confirmButtonText: 'OK',                       
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
                if (result.isDismissed) {
                    window.location.reload();
                }
            });
          }
        }
      });
    },    
    async getMapData(){
      const mapData = await mapDataApi(); 
      this.region1 = mapData.data ;
      // const zz = testtest.find(test => test.cor_x === x && test.cor_y === y);
      // const zz = testtest.find(test => test.status == "inactive");

      // console.log(zz.name)
    },
    // sleep func
    sleep(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    },
    fill(s, gx, gy) {
      this.draw()
      const selectedGrid = this.region1.find(list => list.cor_x === gx && list.cor_y === gy);
      this.landId = selectedGrid.id;
      this.landName = selectedGrid.name;
      this.username = selectedGrid.owner_address;
      let landStatus = selectedGrid.status;    
      console.log(landStatus)  
      if(landStatus == 'active'){
        this.landStatus = "Available"
      }else if (landStatus == 'inactive'){
        this.landStatus = "Unavailable"
      }else{
        this.landStatus = "Sold"
      }
      
      let canvas = document.getElementById("mapCanvas")
      let ctx = canvas.getContext('2d')
      ctx.fillStyle = s;    
      ctx.drawImage(this.redBox, gx * this.size, gy * this.size, this.size, this.size); 

    },
    selectGrid (e) {
      console.log('selected')
      this.infoOpen = true

      // get mouse click position
        var mx = e.offsetX;
        var my = e.offsetY;

        // calculate grid square numbers
        var gx = Math.floor(mx / this.size);
        var gy = Math.floor(my / this.size);
        console.log({
            state: this.state,
            mx:mx,
            my:my,
            gx:gx,
            gy:gy,
            size:this.size
        });
        
        // make sure we're in bounds
        if (gx < 0 || gx >= this.boxes || gy < 0 || gy >= this.boxes) {
            return;
        }

        this.landLotX = gx
        this.landLotY = gy

        this.state[gy][gx] = true;
        this.fill('green', gx, gy);
        
    },
    handleResize () {
      // Calculate new canvas size based on window
      this.draw();
    },
    async draw () {
      // this.clear();
      this.cw = this.size * this.boxes
      this.ch = this.size * this.boxes

      let canvas = document.getElementById("mapCanvas")
      let ctx = canvas.getContext('2d')

      // ctx.translate(gX, gY);
      // ctx.scale(gScale, gScale);
      // ctx.fillStyle = "#FAF7F8";
      ctx.fillStyle = "transparent"
      // this.rect(0,0,this.cw,this.ch);
      // redraw each rect in the this.map[] array
      // for(var i=0;i<this.map.length;i++){
      //     var r=this.map[i];
      //     ctx.fillStyle=r.fill;
      //     this.rect(r.x,r.y,r.width,r.height);
      //     // if (r.img) {
      //     //   this.bgImg(r.img, r.x, r.y, r.width, r.height)
      //     // }
      // }
      // for (let i=0; i <= this.boxes; i++) {
      //   console.log(i);
      //   ctx.strokeRect(this.size*i, 0, 0, this.size); 
      //   // ctx.fillRect(this.size*i, 0, this.size, this.size);
      //   this.map.push({x:this.size*i, y:0, width:this.size, height:this.size})

      //   for (let j=1; j < this.boxes; j++) {
      //     ctx.strokeRect(0, this.size*j, this.size, this.size); // (top left x, y, bottom right x, y)
      //     // ctx.fillRect(0, this.size*j, this.size, this.size);
      //     this.map.push({x:0, y:this.size*j, width:this.size, height:this.size})
      //     ctx.strokeRect(this.size*i, this.size*j, this.size, this.size); 
      //     // ctx.fillRect(this.size*i, this.size*j, this.size, this.size);
      //     this.map.push({x:this.size*i, y:this.size*j, width:this.size, height:this.size})
      //   }
      // }
      // Image
      // var img = document.getElementById('map')
      // ctx.drawImage(img, 0, 40, 240, 240)
      // this.map.push({image: img, x: 0, y: 0, width: 500, height: 500})

      this.bgImg(0,0)
      for(let x = 0; x<this.boxes; x++){
          for(let y =0; y<this.boxes; y++){ 
              // ctx.fillStyle = color[Math.floor(Math.random() * color.length)];
              // ctx.fillRect(30*x,30*y,30,30);
              ctx.strokeRect(this.size*x,this.size*y,this.size,this.size);
              const checkStatus = this.region1.find(list => list.cor_x === x && list.cor_y === y);
              if(checkStatus){
                // console.log(checkStatus.status)
                // await this.sleep(2)
                if(checkStatus.status == "reserved"){
                  ctx.drawImage(this.crossImg, x * this.size, y * this.size, this.size, this.size); 
                }else if(checkStatus.status == "inactive"){
                  ctx.fillStyle = "rgba(130, 130, 130, 0.75)";
                  ctx.fillRect( x * this.size, y * this.size, this.size, this.size)  
                }
              }
              this.map.push({x:this.size*x,y:this.size*y});
          }
      }
    },
    bgImg (x, y) {
      console.log(x, y);
      this.clear()
      let canvas = document.getElementById("mapCanvas")
      let ctx = canvas.getContext('2d')
      var img = document.getElementById('map')
      ctx.beginPath();
      ctx.drawImage(img, x, y, this.cw, this.ch)
      ctx.closePath();
    },
    // rect(x,y,w,h) {
    //   let canvas = document.getElementById("mapCanvas")
    //   let ctx = canvas.getContext('2d')
    //   ctx.beginPath();
    //   ctx.fillStyle = "#2AC161"
    //   ctx.rect(x,y,w,h);
    //   ctx.fillRect(x,y,w,h);
    //   ctx.closePath();
    //   ctx.fill();
    // },
    drag () {
      document.getElementById("mapSvg").style.cursor = "grab"
    },
    scaleHandler () {

    },
    clickZoom(zoom){
      console.log(zoom)
      if(zoom == "+"){
        this.zoomType = 0.2
      }else if(zoom == "-"){
        this.zoomType = -0.2
      }
      if(this.zoomMulti < 5){
        const svgImage = document.getElementById("mapSvg");
        // const svgContainer = document.getElementById("svgContainer");
        this.viewBox = {x:0,y:0,w:svgImage.clientWidth,h:svgImage.clientHeight};
        // svgImage.setAttribute('viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`);
        const svgSize = {w:svgImage.clientWidth,h:svgImage.clientHeight};
        // var isPanning = false;
        // var startPoint = {x:0,y:0};
        // var endPoint = {x:0,y:0};

        var w = this.viewBox.w;
        var h = this.viewBox.h;
        var mx = 558; 
        var my = 326;   
        var zoomAmt = this.zoomType;
        var dw = Math.round(w*zoomAmt);
        var dh = Math.round(h*zoomAmt);
        console.log(zoomAmt, dw, dh)
        var dx = dw*mx/svgSize.w;
        var dy = dh*my/svgSize.h;
        this.viewBox = {x:this.viewBox.x+dx,y:this.viewBox.y+dy,w:this.viewBox.w-dw,h:this.viewBox.h-dh};
        this.scale = svgSize.w/this.viewBox.w;
        if (this.scale < 1) {
          console.log(this.zoomValue, (this.scale*100)/100)
          this.zoomValue -= (this.scale*100)/100;
          this.zoomMulti -= 1;
          console.log(this.zoomValue, (this.scale*100)/100)
          console.log('out '+this.zoomMulti)

        } else {        
          if(this.zoomMulti < 4){
            console.log(this.zoomValue, (this.scale*100)/100)
            this.zoomValue += (this.scale*100)/100;
            this.zoomMulti += 1;
            console.log(this.zoomValue, (this.scale*100)/100)
            console.log('in '+this.zoomMulti)
          }          
        }
        console.log(dw, dh);
        console.log(this.mapViewW - dw)
        if( this.mapViewW - dw >= 680 && this.mapViewW - dw <= 2500){
          this.mapViewX += dx
          this.mapViewY += dy
          this.mapViewW -= dw
          this.mapViewH -= dh
          this.cw -= dw
          this.ch -= dh
        }              
        // svgImage.setAttribute('viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`);
        console.log(this.mapViewX, this.mapViewY, this.mapViewW, this.mapViewH)
        this.draw()
      }
    },
    zoom (e) {
      console.log(e);
      if(this.zoomMulti < 5){
        const svgImage = document.getElementById("mapSvg");
        // const svgContainer = document.getElementById("svgContainer");
        this.viewBox = {x:0,y:0,w:svgImage.clientWidth,h:svgImage.clientHeight};
        // svgImage.setAttribute('viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`);
        const svgSize = {w:svgImage.clientWidth,h:svgImage.clientHeight};
        // var isPanning = false;
        // var startPoint = {x:0,y:0};
        // var endPoint = {x:0,y:0};

        e.preventDefault();
        var w = this.viewBox.w;
        var h = this.viewBox.h;
        var mx = e.offsetX;//mouse x  
        var my = e.offsetY;   
        console.log(mx, my)
        var zoomAmt = 0;
        if(isNaN(Math.sign(-e.deltaY)*0.2)){
          zoomAmt = this.clickZoom; //for btn zoom
        }else{
          zoomAmt = Math.sign(-e.deltaY)*0.2 //for scroll zoom
        }
        var dw = Math.round(w*zoomAmt);
        var dh = Math.round(h*zoomAmt);
        var dx = dw*mx/svgSize.w;
        var dy = dh*my/svgSize.h;
        this.viewBox = {x:this.viewBox.x+dx,y:this.viewBox.y+dy,w:this.viewBox.w-dw,h:this.viewBox.h-dh};
        this.scale = svgSize.w/this.viewBox.w;
        console.log('start'+this.zoomMulti)
        if (this.scale < 1) {
          console.log(this.zoomValue, (this.scale*100)/100)
          this.zoomValue -= (this.scale*100)/100;
          this.zoomMulti -= 1;
          console.log(this.zoomValue, (this.scale*100)/100)
          console.log('out '+this.zoomMulti)

        } else {        
          if(this.zoomMulti < 4){
            console.log(this.zoomValue, (this.scale*100)/100)
            this.zoomValue += (this.scale*100)/100;
            this.zoomMulti += 1;
            console.log(this.zoomValue, (this.scale*100)/100)
            console.log('in '+this.zoomMulti)
          }          
        }
        console.log(dw, dh);
        console.log(this.mapViewW - dw)
        if( this.mapViewW - dw >= 680 && this.mapViewW - dw <= 2500){
          this.mapViewX += dx
          this.mapViewY += dy
          this.mapViewW -= dw
          this.mapViewH -= dh
          this.cw -= dw
          this.ch -= dh
        }              
        // svgImage.setAttribute('viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`);
        console.log(this.mapViewX, this.mapViewY, this.mapViewW, this.mapViewH)
        this.draw()
      }
    },
    handleMouseDown(e){
        console.log("down",this.isDragging);
        var el   = document.getElementById("mapSvg"); 
        var rect = el.getBoundingClientRect(); // get the bounding rectangle

        console.log( rect.width );
        console.log( rect.height);
        // tell the browser we're handling this mouse event
        e.preventDefault();
        e.stopPropagation();
        console.log(e.clientX, e.clientY)
        console.log(this.offsetX, this.offsetY)
        // get the current mouse position
        var mx=parseInt(e.clientX-this.offsetX);
        var my=parseInt(e.clientY-this.offsetY);

        // test each rect to see if mouse is inside
        this.isDragging=true;
        if(mx>rect.x && mx<rect.x+rect.width && my>rect.y && my<rect.y+rect.height){
          console.log("inside svg");
          this.isDragging=true;
        }

        for(var i=0;i<this.map.length;i++){
            var r=this.map[i];
            if(mx>r.x && mx<r.x+r.width && my>r.y && my<r.y+r.height){
                // if yes, set that this.map isDragging=true
                this.isDragging=true;
                // r.isDragging=true;
            }
        }
        // save the current mouse position
        this.startX=mx;
        this.startY=my;
    },
    handleMouseUp(e){
        // tell the browser we're handling this mouse event
        e.preventDefault();
        e.stopPropagation();

        // clear all the dragging flags
        this.isDragging = false;
        // for(var i=0;i<this.map.length;i++){
        //     this.map[i].isDragging=false;
        // }
    },
    handleMouseMove(e){
        // console.log(this.map);
        // console.log("move",this.isDragging);
        // console.log("e.clientX",e.clientX);
        // console.log("this.offsetX",this.offsetX);
        // console.log("e.clientX-this.offsetX",e.clientX-this.offsetX);
        // if we're dragging anything...
        if (this.isDragging && !this.modal.isOpen){

          // tell the browser we're handling this mouse event
          e.preventDefault();
          e.stopPropagation();

          // get the current mouse position
          var mx=parseInt(e.clientX-this.offsetX);
          var my=parseInt(e.clientY-this.offsetY);

          // calculate the distance the mouse has moved
          // since the last mousemove
          var dx=mx-this.startX;
          var dy=my-this.startY;

          // move each rect that isDragging 
          // by the distance the mouse has moved
          // since the last mousemove
          // for(var i=0;i<this.map.length;i++){
          //     var r=this.map[i];
          //     if(r.isDragging){
          //         r.x+=dx;
          //         r.y+=dy;
          //     }
          // }
          this.mapViewX -= dx
          this.mapViewY -= dy

          // this.bgImg (this.map[0].x+dx, this.map[0].y+dy)

          // reset the starting mouse position for the next mousemove
          this.startX=mx;
          this.startY=my;

        } else if (this.isDragging && this.modal.isOpen) {
          // console.log("map");
          // tell the browser we're handling this mouse event
          e.preventDefault();
          e.stopPropagation();

          // get the current mouse position
          mx=parseInt(e.clientX-this.offsetX);
          my=parseInt(e.clientY-this.offsetY);

          // calculate the distance the mouse has moved
          // since the last mousemove
          dx=mx-this.startX;
          dy=my-this.startY;

          // move each rect that isDragging 
          // by the distance the mouse has moved
          // since the last mousemove
          // for(var i=0;i<this.map.length;i++){
          //     var r=this.map[i];
          //     if(r.isDragging){
          //         r.x+=dx;
          //         r.y+=dy;
          //     }
          // }
          this.modalMapViewX -= dx
          this.modalMapViewY -= dy

          // this.bgImg (this.map[0].x+dx, this.map[0].y+dy)


          // redraw the scene with the new rect positions
          this.draw();

          // reset the starting mouse position for the next mousemove
          this.startX=mx;
          this.startY=my;
        }
    },
    async showGrid (e, area){
      this.areaSelected = area;
      console.log(this.areaSelected);
      console.log(e.path[0].id);
      // await this.sleep(400);
      console.log('test')
      this.testModal()        

      // this.$swal.fire({
      //   title: 'Loading',
      //   // html: 'I will close in <b></b> milliseconds.',
      //   timer: 500,
      //   // timerProgressBar: true,
      //   didOpen: () => {
      //       this.$swal.showLoading()
      //   },
      // }).then(() => {
      //   this.testModal()        
      // })

    },
    clear() {
      let canvas = document.getElementById("mapCanvas")
      let ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, this.cw, this.ch);
    },
    // Modal gsap func
    async testModal () {
      // this.createModal(document.querySelector("#modal-1"));
      await this.createModal(document.getElementById("modal-1"));

      this.open()

    },
    onClick() {
      if (this.modal.isOpen) {
        this.close();
      }
    },
    close () {
      this.modal.isOpen = false;
      this.infoOpen = false
      this.modal.animation.reverse();
    },
    createModal(container) {
      
      this.modal.content = container.querySelector(".map-modal-content");
      this.modal.dialog = container.querySelector(".map-modal-dialog");
      this.modal.polygon = container.querySelector(".map-modal-polygon");
      this.modal.svg = container.querySelector(".map-modal-svg");

      // this.modal.dialog.width = 900
      // this.modal.dialog.height = 700
      console.log("polygon", this.modal.dialog);
      
      this.modal.polygon.points.clear()

      this.point1 = this.createPoint(45, 45);
      this.point2 = this.createPoint(55, 45);
      this.point3 = this.createPoint(55, 55);
      this.point4 = this.createPoint(45, 55);
          
      this.modal.animation = gsap.timeline({    
          onReverseComplete: onReverseComplete,
          onStart: onStart,
          paused: true
        })     
        // animation.duration
        .to(this.point1, 0.3, {
          x: 15,
          y: 30,
          ease: Power4.easeIn
        }, 0)
        .to(this.point4, 0.3, {
          x: 5,
          y: 80,
          ease: Power2.easeIn
        }, "-=0.1")
        .to(this.point1, 0.3, {
          x: 0,
          y: 0,
          ease: Power3.easeIn
        })
        .to(this.point2, 0.3, {
          x: 100,
          y: 0,
          ease: Power2.easeIn
        }, "-=0.2")
        .to(this.point3, 0.3, {
          x: 100,
          y: 100,
          ease: Power2.easeIn
        })
        .to(this.point4, 0.3, {
          x: 0,
          y: 100,
          ease: Power2.easeIn
        }, "-=0.1")  
        .to(container, 1, {
          autoAlpha: 1
        }, 0)  
        .to(this.modal.content, 1, {
          autoAlpha: 1
        })

      var body = document.body;
      
      // this.body.removeChild(container);

      function onStart() {
        console.log("start");
        // this.modal.isOpen = true
        body.appendChild(container);

        // container.addEventListener("click", this.onClick);
      }

      function onReverseComplete() {
        console.log("onReverseComplete");
        // this.modal.isOpen = false
        // container.removeEventListener("click", this.onClick);
        this.close()
        body.removeChild(container);
      }
      
      console.log("finish animation");
      // return modal;
    },
    createPoint(x, y) {
      var point = this.modal.polygon.points.appendItem(this.modal.svg.createSVGPoint());
      point.x = x || 0;
      point.y = y || 0;
      return point;
    },
    async open () {
      // let canvas = document.getElementById("mapCanvas")
      // set canvas w & h
      // canvas.style.width = (window.innerWidth > 0) ? window.innerWidth : screen.width
      // canvas.style.height = (window.innerHeight > 0) ? window.innerHeight : screen.height
      
      // let ctx = canvas.getContext('2d')
      // Image
      // var img = document.getElementById('map')
      // ctx.drawImage(img, 0, 0, 900, 700)
      // ctx.drawImage(img, 300, 300, this.wh*3,this.wh*3)
      // ctx.drawImage(img, 300+this.wh*3,300+this.wh*3,this.wh*6,this.wh*6)

      this.modalW = window.innerHeight - this.margin;
      this.modalH = window.innerWidth - this.margin;

      this.modal.isOpen = true;
      this.modal.animation.play().timeScale(2);
      await this.sleep(500)
      this.draw()

    },
    
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.testing{
  position: absolute;
  /* z-index: 2000; */
  left: 14%;
  top: 5%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.testingBtn {
  border-radius: 10px;
  border-color: white;
  font-size: 20px;
  color: purple;
  padding-bottom: 6px;
  font-weight: bolder;
}


.map-page{
  text-align: center;
  position: relative;
}

.st0, .lumi, .retail, .st3, .st4, .st5{
  fill:transparent;
  stroke:transparent;
  stroke-width:1;
  stroke-miterlimit:10;
  position: relative;
  opacity: 0.5;
  transition: 0.5s;
}
#mapSvg {
  width: 75%;
  left:0;
  top:120px;
  z-index: 2;
  margin: 10px auto;
  background: #141e38;
  opacity: 0.7;
}
#modal-1 #retail0 {
  stroke:rgb(0, 208, 255);
}
svg .st0:hover{
  fill: black;
}
svg .lumi:hover{
  fill: blue;
}
svg .retail:hover{
  fill: rgb(0, 208, 255);
}
svg .st3:hover{
  fill: violet;
}
svg .st4:hover {
  fill: red;
}
svg .st5:hover{
  fill: purple;
}
canvas {
  /* position: absolute; */
  /* right: 0; */
    margin: auto;
    z-index: 9;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* Modal styles */
.controls {
  padding: 24px;
}

.map-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(0,0,0,0.65); 
  opacity: 0;
  visibility: hidden;
}

.map-modal-dialog {
  max-width: 80% !important;
  /* width: 80% ; */
  /* height: 88vh;   */
  /* margin-top: 15vh; */
  /* width: 900px;
  height: 700px; */
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.map-modal-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map-modal-polygon {
  fill: #010c00;

}

.map-modal-content {
  position: relative;
  /* top: 0;
  left: 0; */
  /* padding: 24px;   */
  width: 100%;
  visibility: hidden;
  margin: auto;
  opacity: 0;  
  color: rgba(255,255,255,0.87);
  display: flex;
  justify-content: center;
  background-color: transparent !important;
}
.map-modal-map {
  transition: opacity 0.5s ease-in 2s;
}

/*Info Slider Styles*/
.info-slider {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 350px;
  position: fixed;
  right: 0;
  top: 0;
  padding: 0 10px;
  z-index: 99;
  background: #1C232B;
  height: 100%;
}
.top-info {
  background-color: #1C232B;
}
.top-info .sub-title{
  color: white;
  font-size: 14px;
}
.top-info, .bottom-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  padding: 10px;
}
.bottom-info {
  text-align: left;
}
.bottom-info .info-wrapper {
  padding-bottom: 10px;
}
.info-wrapper .sub-title {
  color: #555d62;
}
.info-wrapper .value {
  font-size: 20px;
  color: white;
}
.info-pic {
  width: 98%;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  border: 2px solid #555d62;
  border-radius: 12px;
  align-self: center;
}
.close-slider {
  font-size: 26px;
  align-self: flex-end;
  position: fixed;
  margin: 10px;
  cursor: pointer;
}
.info-slider .title {
  font-weight: bold;
  font-size: 20px;
  color: white;
}

.info-btn button{
  font-size: 16px;
  padding: 8px 38px;
  border-radius: 30px;
}
</style>
