import WalletConnectProvider from "@walletconnect/web3-provider";

const providerWC = new WalletConnectProvider({
    rpc: {
        97: "https://data-seed-prebsc-1-s1.binance.org:8545/" //bsc testnet
        // 56:"https://bsc-dataseed.binance.org/"
        // 137:"https://polygon-rpc.com/"
    },
    qrcodeModalOptions: {
      mobileLinks: [
        "rainbow",
        "metamask",
        "argent",
        "trust",
        "imtoken",
        "pillar",
      ]
    }
});

export async function walletType () {    
    if(sessionStorage.connectType == "WC"){
        console.log(providerWC);
        await providerWC.enable();
        return providerWC;
    }else{
        return window.ethereum;
    }
}
