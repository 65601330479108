import Vue from 'vue'
import App from './App.vue'
import 'bootstrap'
import router from './router'
import $ from "jquery"
import VueSweetalert2 from 'vue-sweetalert2';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'sweetalert2/dist/sweetalert2.min.css';
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'



Vue.config.productionTip = false
Vue.use($)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueSweetalert2)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

$(function ($) {
  "use strict";

  $(document).ready(function () {


      //   magnific popup activation
      $('.play-video').magnificPopup({
          type: 'video'
      });

      $('.img-popup').magnificPopup({
          type: 'image'
      });

      // $('#faqModal').modal('show');

      // Product deal countdown
      $('[data-countdown]').each(function () {
          var $this = $(this),
              finalDate = $(this).data('countdown');
          $this.countdown(finalDate, function (event) {
              $this.html(event.strftime('<span>%D:</span><span>%H:</span><span>%M:</span><span>%S</span>'));
          });
      });
      $('[data-countdown2]').each(function () {
          var $this = $(this),
              finalDate = $(this).data('countdown2');
          $this.countdown(finalDate, function (event) {
              $this.html(event.strftime('<p>%D : <span>Days</span></p> <p>%H : <span>Hours</span></p> <p>%M : <span>Minutes</span></p> <p>%S <span>Seconds</span></p>'));
          });
      });


      // Latest Winner Slider
      var $l_winner_slider = $('.l-winner-slider2');
      $l_winner_slider.owlCarousel({
          loop: true,
          navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
          nav: true,
          dots: false,
          autoplay: false,
          margin: 30,
          autoplayTimeout: 6000,
          smartSpeed: 1000,
          responsive: {
              0: {
                  items: 2
              },
              415: {
                  items: 4
              },
              576: {
                  items: 4
              },
              768: {
                  items: 5
              },
              992: {
                  items: 7
              },
              1200: {
                  items: 9
              },
              1920: {
                  items: 9
              }
          }
      });

      // Latest Winner Slider
      var $l_winner_slider2 = $('.l-winner-slider');
      $l_winner_slider2.owlCarousel({
          loop: true,
          navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
          nav: true,
          dots: false,
          autoplay: false,
          margin: 30,
          autoplayTimeout: 6000,
          smartSpeed: 1000,
          responsive: {
              0: {
                  items: 3
              },
              576: {
                  items: 4
              },
              768: {
                  items: 4
              },
              992: {
                  items: 3
              },
              1200: {
                  items: 3
              },
              1920: {
                  items: 3
              }
          }
      });

      // Exclusive Lottery Slider
      var $ex_lottery_slider = $('.ex-lottery-slider');
      $ex_lottery_slider.owlCarousel({
          loop: true,
          navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
          nav: true,
          dots: false,
          autoplay: false,
          margin: 30,
          autoplayTimeout: 6000,
          smartSpeed: 1000,
          responsive: {
              0: {
                  items: 1
              },
              500: {
                  items: 1
              },
              768: {
                  items: 2
              },
              992: {
                  items: 3
              },
              1200: {
                  items: 3
              },
              1920: {
                  items: 3
              }
          }
      });

      /** Product Details  carousel **/
      var $product_slider = $('.all-slider');
      $product_slider.owlCarousel({
          loop: false,
          dots: false,
          nav: true,
          navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],          
          autoplay: false,
          items: 4,
          margin: 30,
          autoplayTimeout: 6000,
          smartSpeed: 1000,
          responsive: {
              0: {
                  items: 3
              },
              768: {
                  items: 4
              }
          }
      });

      // payment Slider
      var $method_slider = $('.method-slider');
      $method_slider.owlCarousel({
          loop: true,
          navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
          nav: true,
          dots: false,
          autoplay: false,
          margin: 0,
          autoplayTimeout: 6000,
          smartSpeed: 1000,
          responsive: {
              0: {
                  items: 2
              },
              500: {
                  items: 3
              },
              768: {
                  items: 5
              },
              992: {
                  items: 6
              },
              1200: {
                  items: 7
              },
              1920: {
                  items: 7
              }
          }
      });

      // testimonial-slider
      var $testimonial_slider = $('.testimonial-slider');
      $testimonial_slider.owlCarousel({
          loop: true,
          navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
          nav: true,
          dots: false,
          autoplay: false,
          margin: 30,
          autoplayTimeout: 6000,
          smartSpeed: 1000,
          responsive: {
              0: {
                  items: 1
              },
              768: {
                  items: 1
              },
              960: {
                  items: 1
              },
              1200: {
                  items: 1
              }
          }
      });


  });


  /*-------------------------------
      back to top
  ------------------------------*/
  $(document).on('click', '.bottomtotop', function () {
      $("html,body").animate({
          scrollTop: 0
      }, 2000);
  });

  //define variable for store last scrolltop
  $(window).on('scroll', function () {
      var lastScrollTop = '';
      var $window = $(window);
      if ($window.scrollTop() > 0) {
          $(".header").addClass('nav-fixed');
      } else {
          $(".header").removeClass('nav-fixed');
      }

      /*---------------------------
          back to top show / hide
      ---------------------------*/
      var st = $(this).scrollTop();
      var ScrollTop = $('.bottomtotop');
      if ($(window).scrollTop() > 1000) {
          ScrollTop.fadeIn(1000);
      } else {
          ScrollTop.fadeOut(1000);
      }
      lastScrollTop = st;
      console.log(lastScrollTop)

  });

  $(window).on('load', function () {

      /*---------------------
          Preloader
      -----------------------*/
      var preLoder = $("#preloader");
      preLoder.addClass('hide');
      var backtoTop = $('.back-to-top');
      /*-----------------------------
          back to top
      -----------------------------*/
      backtoTop = $('.bottomtotop');
      backtoTop.fadeOut(100);

  });

});