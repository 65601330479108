<template>
  <div id="app">
    <header class="header">
      <div class="overlay"></div>
      <!-- Top Header Area Start -->
      <section class="top-header" v-bind:class="{ hide: hideTopBar}">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="content">
                <div class="left-content">
                  <!-- <ul class="left-list">
                    <li>
                      <p>
                        <i class="fas fa-headset"></i>	Support
                      </p>
                    </li>
                  </ul> -->
                  <ul class="top-social-links">
                    <li>
                      <a href="https://twitter.com/metadeios" target="_blank">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://discord.com/invite/gU35KRrR" target="_blank">
                        <i class="fab fa-discord"></i>
                      </a>
                    </li>
                    <!-- <li>
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>                    
                    <li>
                      <a href="#">
                        <i class="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li> -->
                  </ul>
                </div>
                <div class="right-content">
                  <ul class="right-list">
                    <li>
                      <div class="language-selector">
                        <select name="language" class="language">
                          <option value="1">EN</option>
                          <option value="2">IN</option>
                          <option value="3">BN</option>
                        </select>
                      </div>
                    </li>
                    <li>
                      <div class="notofication"  data-toggle="modal" data-target="#usernotification">
                        <i class="far fa-bell"></i>
                      </div>
                    </li>
                    <li>
                      <div class="message"  data-toggle="modal" data-target="#usermessage">
                        <i class="far fa-envelope"></i>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Top Header Area End -->
      <!--Main-Menu Area Start-->
      <div class="mainmenu-area hideHeader">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">                 
              <nav class="navbar navbar-expand-lg navbar-light">
                <!-- <a  href="index.html">
                  
                </a> -->
                <router-link class="navbar-brand" to="/"><img src="../src/assets/images/logo/Logo-Hori.png" alt=""></router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_menu" aria-controls="main_menu"
                  aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse fixed-height" id="main_menu">
                  <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                      <router-link class="nav-link" to="/home" >Home</router-link>                                          
                    </li>
                    <li class="nav-item">
                      <!-- <a class="nav-link" href="/map">Map</a> -->
                      <router-link class="nav-link" to="/map">Map</router-link>  
                    </li>
                  </ul>
                  <div >
                      <button class="connectBtn" v-bind:class="{ hide: !isMeta}">
                          {{ userAddress ? userAddress : "Connect Wallet" }}
                      </button>
                      <button class="connectBtn" v-bind:class="{ hide: isMeta}" data-toggle="modal" data-target="#walletModal">{{ userAddress ? userAddress : "CONNECT WALLET" }}</button>
                  </div>               
                  <!-- <a href="#" class="mybtn1"  data-toggle="modal" data-target="#signin" data-bs-toggle="modal" data-bs-target="#walletModal"> CONNECT WALLET</a> -->
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <!--Main-Menu Area Start-->
    </header>
    <!-- Header Area End  -->

    <div class="router-wrap" id="home"><router-view></router-view></div>

    <!-- modal -->
    <div class="modal fade" tabindex="-1" id="walletModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-bind:class="{ hide: !connected}">Connect Wallet</h5>
                    <h5 class="modal-title" v-bind:class="{ hide: connected}">Disconnect Wallet?</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body" v-bind:class="{ hide: !connected}">                     
                    <div class="wallet-choice">
                        <button @click="connectWallet">
                            <img src="../src/assets/images/icon/metamask.png" alt="" style="width: 40px">
                            <div>Metamask</div>
                        </button>
                    </div>
                    <!-- <div class="wallet-choice">
                        <button @click="connectWalletWC">
                            <img src="../src/assets/images/icon/wallet_connect.png" alt="" style="width: 40px">
                            <div>Wallet Connect</div>
                        </button>
                    </div> -->
                </div>
                <div class="modal-body" v-bind:class="{ hide: connected}">
                    <!-- <div>
                        <button class="disconnect-btn" @click="disconnectWC"> <b>Disconnect</b>  <img src="../src/assets/images/icon/logout.png" alt=""></button>        
                    </div> -->
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
import {ethers} from "ethers";
// import axios from "axios";
// import WalletConnectProvider from "@walletconnect/web3-provider";

export default {
  name: 'App',
  data(){
    return{
        userAddress: "",
        // walletStatus: false,
        connectStatus: false,
        connect: false,   
        connected: true,   
        user: "",   
        isMeta: false,
        hideTopBar: false,
    }
  },
  watch: {
    $route (to, from){
      console.log(to,from)
      if(this.$route.name == "Map"){
        console.log(this.$route.name);
        this.hideTopBar = true;
      }else{
        this.hideTopBar = false;
      }
    }  
  },

  mounted: async function (){

    if(this.$route.name == "Map"){
      console.log(this.$route.name);
      this.hideTopBar = true;
    }else{
      this.hideTopBar = false;
    }
    
    this.getAddress();
    window.ethereum.on('disconnect', () => {
        if(sessionStorage.connectType != "WC"){
            this.connectStatus = false;
            sessionStorage.address = "";            
            window.location.reload();
        }
        
    })

    window.ethereum.on('accountsChanged', () => {
    
        if(sessionStorage.connectType != "WC"){
            this.connectStatus = false;        
            this.connected = false;
            this.connect  = true;
            sessionStorage.connect = "";
            sessionStorage.bt = "";
            window.location.reload();
        }
    })

    window.ethereum.on('chainChanged', (res) => {
        if(sessionStorage.connectType != "WC"){
            if(res != '0x61'){
                this.$swal.fire({
                    icon: 'error',
                    title: 'Not connected to BSC network',
                    text: 'Please connect to BSC Mainnet chain.',
                    confirmButtonText: 'Return',                     
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/home");
                    }
                });
            }
        }
        // window.location.reload();
    })
  },
  methods: {
    async connectWallet() {
      if(window.ethereum == undefined){
          alert("Please install metamask");
      }else{
          let checkNetwork = this.switchNetwork();
          if(!checkNetwork){
              // return false;
          }
          console.log('test')
          await window.ethereum.request({ method: 'eth_requestAccounts' });
  
          if(sessionStorage.connect == "true"){
              console.log("can")
          }else{
              console.log('ok')
              let provider = new ethers.providers.Web3Provider(window.ethereum)                
              const signer = provider.getSigner()

              // let a = await axios.post('https://api.langkahsheraton.com/api/sheraton/auth/users/login', {
              //     address: await signer.getAddress(),
              // })
              // sessionStorage.bt = a.data.access_token    
              sessionStorage.connect = true

              this.user = await signer.getAddress();
              // this.connectStatus = true;
              this.walletStatus = !this.walletStatus;
              this.userAddress = this.user.substr(0, 5) + "...." + this.user.substr(-4, 4);
              this.connect = true;
              this.connected = false;
              window.location.reload()
          }
      }   
    },
    async switchNetwork(){
        console.log('switch')
        // const { ethers } = require("ethers");
        // let networkId = ethers.utils.hexlify(80001);
        // alert(networkId)

        //97 (0x61) bsc testnet chain //56 (0x38) mainnet
        //137 (0x89) polygon chain (testnet 80001, hex: 0x13881)

        try {
            await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: "0x61" }],
            });
        } catch (switchError) {
            alert("Please add BSC network to your metamask")
            return false
        }
        return true;
    },
    async getAddress(){
        if(sessionStorage.connect == "true"){
            // console.log('test')
            // await window.ethereum.request({ method: "eth_requestAccounts" });
            // this.user = window.ethereum.selectedAddress;
            let provider;
            provider = new ethers.providers.Web3Provider(window.ethereum)
            this.isMeta = true;

            // if(sessionStorage.connectType == "WC"){
            //     await providerWC.enable();
            //     provider = new ethers.providers.Web3Provider(providerWC)
            //     const chainId = await providerWC.request({ method: "eth_chainId" });
            //     if (chainId != 137){
            //         this.$swal.fire({
            //             icon: 'error',
            //             title: 'Not connected to Polygon network',
            //             text: 'Please select Polygon Mainnet chain in your Metamask App and reconnect.',
            //             confirmButtonText: 'Return',
            //             // allowOutsideClick: false,                        
            //         }).then(async (result) => {
            //             if (result.isConfirmed || result.isDismissed) {
            //                 sessionStorage.connect = "";
            //                 sessionStorage.connectType = "";
            //                 await providerWC.disconnect();
            //                 window.location.reload()

            //             }
            //         });
            //     }
            //     this.isMeta = false;                   
            // }else{
            //     provider = new ethers.providers.Web3Provider(window.ethereum)
            //     this.isMeta = true;
            // }

            console.log(provider);
            const signer = provider.getSigner()
            console.log(signer);
            this.user = await signer.getAddress(),
            console.log(this.user)
            this.connectStatus = true;
            this.walletStatus = !this.walletStatus;
            this.userAddress = this.user.substr(0, 5) + "...." + this.user.substr(-4, 4);
            this.connect = true;
            this.connected = false;
          
            // console.log(this.userAddress)
        }
    },
  }
    
}
</script>

<style>

@import 'assets/css/bootstrap.min.css';
@import 'assets/css/plugin.css';
@import 'assets/css/style.css';
@import 'assets/css/responsive.css';

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

.hideHeader{
  
  transition: all 0.3s ease-in;
}

.hide {
    display: none !important;
}

body{
  background: #1f0644;
}

.header .right-list {
  font-size: 18px;
  margin: 0;
}

.header .mainmenu-area .navbar .navbar-brand img {
  max-width: 200px;
}

.connectBtn {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  color: white;
  padding: 12px 26px;
  border-radius: 50px;
  box-shadow: inset 0px 0px 10px 7px #fea036;
  transition: all 0.3s ease-in;
  display: inline-block;
  background: transparent;
  border: none;
  margin-left: 20px;
}
.connectBtn:hover {
  color: #fff;
  box-shadow: inset 0px 0px 10px 7px #00a2ff;
  border: none;
}

.connectBtn:focus {
  outline: none;
  /* box-shadow: none; */
}

#walletModal .btn-close{
  background: none;
  border: none;
  color: white;
  font-size: 25px;
  align-items: center;
  height: 20px;
  display: flex;
}

#walletModal .modal-header, #walletModal .modal-footer{
  border: none;
}

#walletModal .modal-body{
    display: flex;
    justify-content: center;

}

#walletModal .modal-content{
    width: 90%;
    margin: 10rem auto;
    background-color: #250752;
    box-shadow: inset 0px 0px 12px 4px #00a2ff;
    border-radius: 20px;
    padding: 10px;
}


#walletModal .wallet-choice{
    width: 50%;
    text-align: center;
}

#walletModal .wallet-choice button{
    background: transparent;
    border: none;
    color: white;
}

#walletModal .wallet-choice button:hover{
    opacity: 0.8;
}

#walletModal .disconnect-btn{
    background-color: rgba(150,76,63,255);
    border: transparent;
    color:white;
    border-radius: 20px;
    padding: 7px 13px;
    font-size: 20px;
}

#walletModal .disconnect-btn img{
    width: 24px;
    margin-left: 10px;
}

.header .right-list{
  padding-right: 25px;
}

.header .top-social-links {
  margin-bottom: 0;
}

.header .content .left-content {
  display: flex;
  align-items: center;
}

@import'~bootstrap/dist/css/bootstrap.css';

</style>
